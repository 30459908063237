// Import các thư viện cần thiết
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
  Grid,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from "@mui/material";
import {
  ArrowBackIosNewOutlined,
  ConfirmationNumber,
  Place,
  AccessTime,
  Delete,
  ExpandMore,
  InfoOutlined,
  CheckCircle, // Icon thêm vào nút xác nhận đặt lịch
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// Import Redux actions
import {
  selectedPromoCodeData,
  setPromoCode,
  setSelectedPromocode,
} from "../redux/Promocode";
import { cartDetails, orderCartDetailsReset } from "../redux/orderCartDetails";
import { resetState } from "../redux/cart";
import api from "../API/apiCollection";
import { formatMoney } from "../util/Helper"; // Đảm bảo rằng bạn đã tạo hàm này

// Styled components
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const BookingInfoDrawerNew = ({ setForm, setPromo, setBooking, addressForm }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State và biến
  const [rows, setRows] = useState([]);
  const [amount, setAmount] = useState(0);
  const [visitingCharges, setVisitingCharges] = useState(0);
  const [addressInfo, setAddressInfo] = useState(null);
  const categories = useSelector((state) => state.Pages.category);



  // Sử dụng Redux selectors
  const settings = useSelector((state) => state.Settings)?.settings;
  const currency_symbol = settings?.app_settings?.currency || "₫";
  const delivery_type = useSelector(
    (state) => state.DeliveryAddress
  )?.deliveryType;
  const orderDetails = useSelector(
    (state) => state.OrderCartDetails
  )?.orderDetails;
  const deliveryAddress = useSelector(
    (state) => state.DeliveryAddress
  )?.delivery;
  const selectedPromo = useSelector(selectedPromoCodeData);
  const AllCartData = useSelector((state) => state.cart);
  const promocode = useSelector((state) => state.Promocode);
  const coupanCodes = promocode.promocode;
  const selectedPromoCode = promocode.selectedPromoCode;

  const decimal_point = 2; // Điều chỉnh nếu cần

    
  // Lấy các state và setter từ addressForm
  const {
    selectedProvince,
    selectedFamousPlace,
    otherLocation,

  } = addressForm;


  useEffect(() => {
    // Thiết lập amount và rows dựa trên dữ liệu giỏ hàng
    let cartData =
      AllCartData?.isFrom === "reorder"
        ? AllCartData?.reOrder
        : AllCartData?.base_cart;
    if (cartData) {
      let totalAmount = Number(cartData.sub_total);
      if (delivery_type === "Home") {
        totalAmount += Number(cartData.visiting_charges);
        setVisitingCharges(Number(cartData.visiting_charges));
      }
      setAmount(totalAmount);
      setRows(cartData.data);
    }
  }, [AllCartData, delivery_type]);

  useEffect(() => {
    if (deliveryAddress) {
      setAddressInfo(deliveryAddress);
    }
  }, [deliveryAddress]);

  // Xử lý nút quay lại
  const back = () => {
    setBooking(false);
    setForm(true);
  };

  // Mở drawer mã giảm giá
  const openPromo = () => {
    setBooking(false);
    setPromo(true);
  };

  // Xóa mã giảm giá
  const removePromo = () => {
    dispatch(setPromoCode([]));
    if (selectedPromo) {
      dispatch(setSelectedPromocode(null));
      toast.success(t("Đã xóa mã giảm giá"));
    } else {
      toast.error(t("Không có mã giảm giá nào được chọn"));
    }
  };

 // Đặt hàng (không liên quan đến thanh toán)
const placeOrder = async () => {
  let address = "";
  if (delivery_type === "Home") {
    address = deliveryAddress;
  }

  try {
    const result = await api.placeOrder({
      // Các trường cũ
      date: orderDetails?.date,
      time: orderDetails?.slot,
      addressId: delivery_type === "Home" ? address?.id : "",
      order_note: orderDetails?.orderNote,
      promo_code_id: selectedPromoCode?.id,
      // Bổ sung các trường mới
      city_id: selectedProvince?.id,
      famous_place_id: selectedFamousPlace?.id,
      otherLocation: otherLocation ,
    });

    toast.success(t("Đặt dịch vụ thành công"));

    if (!result.error) {
      setTimeout(async () => {
        await api.add_transactions({
          orderID: result.data.order_id,
          status: "success",
        });

        dispatch(orderCartDetailsReset());
        dispatch(resetState());
        navigate("/profile/booking/services/" + result.data.order_id);
        dispatch(cartDetails({ orderNote: "" }));
      }, 2000);
    }
  } catch (error) {
    console.error("error", error);
    toast.error(t("Đặt hàng thất bại, vui lòng thử lại."));
  }
};
  // 1. Cấu hình tỷ lệ trả trước
  const DEPOSIT_PERCENTAGE = 30; // Có thể thay đổi nếu cần

  // 2. Tính toán tổng cộng, số tiền trả trước và số tiền còn lại
  console.log("AllCartData", AllCartData);
  const subtotal =
    AllCartData?.isFrom === "reorder"
      ? AllCartData?.reOrder?.sub_total
      : AllCartData?.base_cart?.sub_total;
  const totalAmount =
    subtotal +
    (delivery_type === "Home" ? visitingCharges : 0) -
    (coupanCodes.length > 0 ? coupanCodes[0]?.final_discount : 0);
  const depositAmount = (totalAmount * DEPOSIT_PERCENTAGE) / 100;
  const remainingAmount = totalAmount - depositAmount;

  return (
    <Box
      sx={{
        width: "100%",
        p: 3,
        backgroundColor: theme.palette.background.default,
      }}
    >
      {/* Header với tiêu đề và nút quay lại */}
      <Box mb={3} display="flex" alignItems="center">
        <IconButton onClick={back} size="small">
          <ArrowBackIosNewOutlined
            sx={{ color: theme.palette.text.primary }}
            fontSize="small"
          />
        </IconButton>
        <Typography variant="h5" fontWeight={600} ml={1}>
          {t("Xác nhận đặt lịch dịch vụ")}
        </Typography>
      </Box>
      <Divider sx={{ mb: 3 }} />

      <Box sx={{ px: 2 }}>
        {/* Thông tin đặt dịch vụ */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Box display="flex" alignItems="center">
                  <AccessTime
                    sx={{ mr: 2, color: theme.palette.primary.main }}
                  />
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      {t("Ngày đặt")}
                    </Typography>
                    <Typography variant="h6" fontWeight="bold">
                      {dayjs(orderDetails?.date).format("DD/MM/YYYY")},{" "}
                      {orderDetails?.slot}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {delivery_type === "Home" && (
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <Place sx={{ mr: 2, color: theme.palette.primary.main }} />
                    <Box>
                      <Typography variant="subtitle2" color="text.secondary">
                        {t("Địa chỉ của bạn")}
                      </Typography>
                      <Typography variant="h6" fontWeight="bold">
                        {addressInfo?.address}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          )}

          {/* Nút áp dụng mã giảm giá dạng secondary (outlined) */}
          <Grid item xs={12}>
            <Button
              variant="outlined"
              fullWidth
              onClick={openPromo}
              sx={{
                textTransform: "none",
                borderRadius: "12px",
                borderColor: theme.palette.secondary.main,
                color: theme.palette.secondary.main,
                fontWeight: "bold",
                fontSize: "1rem",
                py: 1.5,
                transition: "all 0.3s ease",
                "&:hover": {
                  borderColor: theme.palette.secondary.dark,
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              {t("Áp dụng mã giảm giá")}
            </Button>
          </Grid>

          {coupanCodes.length > 0 && (
            <Grid item xs={12}>
              <Card
                variant="outlined"
                sx={{
                  borderRadius: "16px",
                  boxShadow: "0 8px 24px rgba(0, 0, 0, 0.1)",
                  overflow: "hidden",
                  background: theme.palette.background.paper,
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-4px)",
                    boxShadow: "0 12px 32px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <ConfirmationNumber
                      sx={{
                        mr: 2,
                        fontSize: "2rem",
                        color: theme.palette.primary.main,
                        transition: "color 0.3s ease",
                        "&:hover": {
                          color: theme.palette.secondary.main,
                        },
                      }}
                    />
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                          color: theme.palette.text.primary,
                        }}
                      >
                        {selectedPromoCode?.promo_code}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color:
                            "linear-gradient(135deg, #667EEA 0%, #764BA2 100%)",
                          fontWeight: "medium",
                        }}
                      >
                        {selectedPromoCode?.discount}
                        {selectedPromoCode?.discount_type === "percentage"
                          ? "%"
                          : currency_symbol}
                      </Typography>
                    </Box>
                    <IconButton
                      onClick={removePromo}
                      sx={{
                        color: theme.palette.error.main,
                        transition: "transform 0.2s ease",
                        "&:hover": {
                          transform: "scale(1.1)",
                          color: theme.palette.error.dark,
                        },
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>

        {/* Bảng chi tiết giỏ hàng */}
        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table>
            <TableBody>
              {rows?.length > 1 &&
                rows.map((row) => (
                  <StyledTableRow key={row.id}>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {row.servic_details.title}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="subtitle1">{row.qty}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="subtitle1">
                        {formatMoney(
                          row.servic_details.price_with_tax,
                          currency_symbol
                        )}
                      </Typography>
                    </TableCell>
                  </StyledTableRow>
                ))}
              <StyledTableRow>
                <TableCell>
                  <Typography variant="subtitle2" color="text.secondary">
                    {t("Tạm tính")}
                  </Typography>
                </TableCell>
                <TableCell colSpan={2} align="right">
                  <Typography variant="subtitle1">
                    {formatMoney(subtotal, currency_symbol)}
                  </Typography>
                </TableCell>
              </StyledTableRow>
              {delivery_type === "Home" && (
                <StyledTableRow>
                  <TableCell>
                    <Typography variant="subtitle2" color="text.secondary">
                      {t("Phí dịch vụ")}
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={2} align="right">
                    <Typography variant="subtitle1">
                      +{formatMoney(visitingCharges, currency_symbol)}
                    </Typography>
                  </TableCell>
                </StyledTableRow>
              )}
              {coupanCodes.length > 0 && (
                <StyledTableRow>
                  <TableCell>
                    <Typography variant="subtitle2" color="text.secondary">
                      {t("Giảm giá")}
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={2} align="right">
                    <Typography variant="subtitle1">
                      -
                      {formatMoney(
                        coupanCodes[0]?.final_discount,
                        currency_symbol
                      )}
                    </Typography>
                  </TableCell>
                </StyledTableRow>
              )}
              {/* Hiển thị trả trước (30%) rõ ràng */}
              <StyledTableRow>
                <TableCell>
                  <Typography variant="h6" fontWeight="bold">
                    {t(`Trả trước (${DEPOSIT_PERCENTAGE}%)`)}
                  </Typography>
                </TableCell>
                <TableCell colSpan={2} align="right">
                  <Typography variant="h6" fontWeight="bold" color="primary">
                    {formatMoney(depositAmount, currency_symbol)}
                  </Typography>
                </TableCell>
              </StyledTableRow>
              {/* Hiển thị Tổng cộng trong Accordion */}
              <StyledTableRow>
                <TableCell colSpan={3} sx={{ padding: 0 }}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h6" fontWeight="bold">
                        {t("Chi tiết thanh toán")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box display="flex" justifyContent="space-between" mb={1}>
                        <Typography variant="body1">
                          {t("Tổng cộng")}
                        </Typography>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="primary"
                        >
                          {formatMoney(totalAmount, currency_symbol)}
                        </Typography>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* Thông tin trả trước */}
        <Card variant="outlined" sx={{ mt: 2 }}>
          <CardContent>
            <Box display="flex" alignItems="center">
              <InfoOutlined sx={{ mr: 1, color: theme.palette.warning.main }} />
              <Typography variant="body2" color="text.secondary">
                {t(
                  `Sau khi nhiếp ảnh gia xác nhận, bạn chỉ cần trả trước ${DEPOSIT_PERCENTAGE}% để hoàn tất đơn hàng. Số tiền còn lại sẽ được thanh toán khi nhận dịch vụ.`
                )}
              </Typography>
            </Box>
          </CardContent>
        </Card>

        {/* Nút xác nhận đặt lịch với icon */}
        <Box sx={{ mt: 4 }}>
          <Button
            variant="contained"
            fullWidth
            onClick={placeOrder}
            sx={{
              textTransform: "none",
              borderRadius: "12px",
              background: "linear-gradient(135deg, #6B73FF 0%, #000DFF 100%)",
              color: "white",
              fontWeight: "bold",
              fontSize: "1rem",
              py: 1.5,
              boxShadow: "0 4px 14px rgba(0, 0, 0, 0.1)",
              transition: "all 0.3s ease",
              "&:hover": {
                background:
                  "linear-gradient(135deg, #5A67D8 0%, #0A0FDD 100%)",
                boxShadow: "0 6px 20px rgba(0, 0, 0, 0.15)",
              },
            }}
          >
            <CheckCircle sx={{ mr: 1 }} />
            {t("Xác nhận đặt lịch")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default BookingInfoDrawerNew;