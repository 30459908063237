// src/hooks/useAddressForm.js
import { useState, useCallback } from "react";

export const useAddressForm = (initialNote = "") => {
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [filteredFamousPlaces, setFilteredFamousPlaces] = useState([]);
  const [selectedFamousPlace, setSelectedFamousPlace] = useState(null);
  const [openOtherLocation, setOpenOtherLocation] = useState(false);
  const [otherLocation, setOtherLocation] = useState("");
  const [note, setNote] = useState(initialNote);
  const [visibleFamousPlaces, setVisibleFamousPlaces] = useState(4);

  const resetFamousPlace = useCallback(() => {
    setSelectedFamousPlace(null);
  }, []);

  return {
    selectedProvince,
    setSelectedProvince,
    filteredFamousPlaces,
    setFilteredFamousPlaces,
    selectedFamousPlace,
    setSelectedFamousPlace,
    openOtherLocation,
    setOpenOtherLocation,
    otherLocation,
    setOtherLocation,
    note,
    setNote,
    visibleFamousPlaces,
    setVisibleFamousPlaces,
    resetFamousPlace,
  };
};