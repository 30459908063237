import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Footer from "./Footer";
import { darkTheme, lightTheme } from "../../Theme";
import { Paper, useMediaQuery } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Header from "./Header/Header";
import { LayoutProps } from "../../typescriptTypes/globalTypes";
import { changeMode, isDarkMode } from "../../redux/Theme";
import { useDispatch, useSelector } from "react-redux";

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const darkMode = useSelector(isDarkMode);
  const dispatch = useDispatch();

  useEffect(() => {
    if (darkMode) {
      dispatch(changeMode(true));
    }
  }, [dispatch, darkMode]);

  const handleChangeLight = (): void => {
    dispatch(changeMode(false));
  };

  const handleChangeDark = (): void => {
    dispatch(changeMode(true));
  };

  // Sử dụng useMediaQuery để kiểm tra kích thước màn hình
  const isBottomNav = useMediaQuery("(max-width:911px)");

  // Các biến chuyển động cho motion.div
  const pageVariants = {
    initial: { x: "100%", opacity: 0 },
    in: { x: 0, opacity: 1 },
    out: { x: "-100%", opacity: 0 },
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.3,
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Paper
        style={{
          minHeight: "100vh",      // Chiều cao tối thiểu của container bằng viewport
          display: "flex",
          flexDirection: "column", // Sắp xếp theo cột
        }}
      >
        {/* Header không bị ảnh hưởng bởi animation */}
        <Header
          check={darkMode}
          changeLight={handleChangeLight}
          changeDark={handleChangeDark}
        />
        {/* Container cho nội dung chính với flex: 1 để chiếm không gian còn lại */}
        <div style={{ flex: 1 }}>
          {isBottomNav ? (
            <AnimatePresence mode="wait">
              <motion.div
                key={location.pathname} // Sử dụng location để kích hoạt animation khi route thay đổi
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                <div className="layout-style">{children}</div>
              </motion.div>
            </AnimatePresence>
          ) : (
            <div className="layout-style">{children}</div>
          )}
        </div>
        {/* Footer sẽ luôn nằm dưới cùng của container */}
        {isBottomNav ? <div></div> : <Footer />}
      </Paper>
    </ThemeProvider>
  );
};

export default Layout;