import {
  Add,
  ArrowBackIosNewOutlined,
  CloseRounded,
} from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  Divider,
  IconButton,
  Radio,
  Typography,
  useTheme,
  Card,
  CardMedia,
  Paper,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { handleClose, handleOpen } from "../config/config";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { t } from "i18next";
import Calendar from "react-calendar";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { dateDetails, slotDetails } from "../redux/orderCartDetails";
import "react-calendar/dist/Calendar.css";
import profileNoBooking from "../Images/no-booking.png";
import "./customCalendar.css"; // File CSS cho lịch đã được tùy chỉnh

const dayMap = {
  0: "sunday",
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday",
};

const ConfirmDateTime = ({ setForm, isSelectSlote }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(null);
  const [timeSlot, setTimeSlot] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [customTime, setCustomTime] = useState(false);
  const [customTimeValue, setCustomTimeValue] = useState("");
  const [selectedDate, setSelectedDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [noSlotAvailable, setNoSlotAvailable] = useState("");

  const orderDetails = useSelector((state) => state.OrderCartDetails)?.orderDetails;
  const baseCart = useSelector((state) => state.cart)?.base_cart;
  const allDataCart = useSelector((state) => state.cart);
  const provider_info = useSelector((state) => state.cart)?.provider_info;

  const customSelectedTime = orderDetails && orderDetails.slot;
  const selectedCalendarDate = orderDetails && orderDetails.date;

  const advanceBookingDays =
    allDataCart?.isFrom === "cart"
      ? baseCart?.advance_booking_days
      : allDataCart?.reOrder?.advance_booking_days;
  const disableDateAfter = dayjs().add(advanceBookingDays - 1, "day");

  function back() {
    handleClose(isSelectSlote);
    handleOpen(setForm);
  }

  useEffect(() => {
    generateTimeSlotsForSelectedDate();
    // eslint-disable-next-line
  }, [selectedDate, provider_info]);

  const isDayOpen = (date) => {
    if (!provider_info) return false;
    const dayOfWeek = date.day();
    const dayKey = dayMap[dayOfWeek];
    const is_open = provider_info[`${dayKey}_is_open`];
    return is_open === "1";
  };

  const shouldDisableDate = (date) => {
    const today = dayjs().startOf("day");
    if (date.isBefore(today) || date.isAfter(disableDateAfter)) {
      return true;
    }
    if (!isDayOpen(date)) {
      return true;
    }
    return false;
  };

  const getDisableReason = (date) => {
    const today = dayjs().startOf("day");
    if (date.isBefore(today) || date.isAfter(disableDateAfter)) {
      return "system";
    }
    if (!isDayOpen(date)) {
      return "provider";
    }
    return null;
  };

  const calenderDateSelect = (value) => {
    setSelectedDate(dayjs(value).format("YYYY-MM-DD"));
  };

  const generateTimeSlotsForSelectedDate = () => {
    if (!provider_info) {
      setTimeSlot([]);
      setNoSlotAvailable("Không có thông tin nhà cung cấp");
      return;
    }

    const dateObj = dayjs(selectedDate);
    const dayOfWeek = dateObj.day();
    const dayKey = dayMap[dayOfWeek];

    const is_open = provider_info[`${dayKey}_is_open`];
    const opening_time = provider_info[`${dayKey}_opening_time`];
    const closing_time = provider_info[`${dayKey}_closing_time`];

    if (is_open === "1") {
      const slots = generateHourlySlots(opening_time, closing_time);
      if (slots.length === 0) {
        setNoSlotAvailable("Không có slot khả dụng vào ngày này.");
      } else {
        setNoSlotAvailable("");
      }
      setTimeSlot(slots);
    } else {
      setTimeSlot([]);
      setNoSlotAvailable("Ngày này nhà cung cấp không mở cửa.");
    }
  };

  const generateHourlySlots = (openingTime, closingTime) => {
    const start = dayjs(selectedDate + " " + openingTime);
    const end = dayjs(selectedDate + " " + closingTime);

    if (!end.isAfter(start)) {
      return [];
    }

    const slots = [];
    let current = start;
    while (current.isBefore(end)) {
      const time = current.format("HH:mm:ss");
      slots.push({
        time,
        is_available: 1,
      });
      current = current.add(1, "hour");
    }
    return slots;
  };

  const handleChange = (slot, index) => {
    setChecked(index);
    setCustomTimeValue(slot.time);
    dispatch(slotDetails(slot.time));
    setSelectedTimeSlot(slot.time);
  };

  const customTimeSelected = () => {
    handleOpen(setCustomTime);
  };

  const handleCustomTime = () => {
    dispatch(slotDetails(customTimeValue));
    handleClose(setCustomTime);
    setSelectedTimeSlot(customTimeValue);
  };

  const handleTimeChange = (selectedTime) => {
    const formattedTime = selectedTime && selectedTime.format("HH:mm:ss");
    setCustomTimeValue(formattedTime);
  };

  const OpenNext = () => {
    if ((selectedTimeSlot === "" || !selectedTimeSlot) && !customSelectedTime) {
      return toast.error("Vui lòng chọn giờ chụp trước khi tiếp tục.");
    }
    dispatch(dateDetails(selectedDate));
    handleClose(isSelectSlote);
    handleOpen(setForm);
  };

  const getTimeOfDay = (time) => {
    const hours = parseInt(time.split(":")[0], 10);
    if (hours >= 0 && hours < 6) return "Rạng sáng";
    else if (hours < 12) return "Sáng";
    else if (hours < 14) return "Trưa";
    else if (hours < 18) return "Chiều";
    else if (hours < 20) return "Chiều tối";
    else if (hours < 24) return "Tối";
    return "Nửa đêm";
  };

  return (
    <Box p={2}>
      {/* Header */}
      <Box display="flex" alignItems="center" mb={2}>
        <IconButton onClick={back} sx={{ mr: 1 }}>
          <ArrowBackIosNewOutlined />
        </IconButton>
        <Typography variant="h6" fontWeight="bold">
          {t("Chọn lịch book thợ chụp hình")}{" "}
          {allDataCart?.isFrom === "cart"
            ? baseCart.company_name
            : allDataCart?.reOrder?.company_name}
        </Typography>
      </Box>
      <Divider sx={{ mb: 3 }} />

      {/* Chọn ngày */}
      <Paper
        variant="outlined"
        sx={{
          p: 2,
          borderRadius: 2,
          mb: 3,
          boxShadow: "0 4px 12px rgba(0,0,0,0.08)",
        }}
      >
        <Typography variant="subtitle1" fontWeight="600" mb={1}>
          {t("Chọn ngày")}
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          <Calendar
            value={selectedCalendarDate ? selectedCalendarDate : dayjs(selectedDate).toDate()}
            onChange={(newValue) => {
              calenderDateSelect(newValue);
            }}
            prev2Label={null}
            next2Label={null}
            tileDisabled={({ date }) => shouldDisableDate(dayjs(date))}
            tileClassName={({ date, view }) => {
              if (view === "month") {
                let classes = "";
                const dayInstance = dayjs(date);
                const today = dayjs().startOf("day");
                const selected = dayjs(selectedDate);
                // Đánh dấu ngày hôm nay: chỉ in đậm chữ
                if (dayInstance.isSame(today, "day")) {
                  classes += " calendar-today";
                }
                // Đánh dấu ngày được chọn
                if (dayInstance.isSame(selected, "day")) {
                  classes += " calendar-selected";
                }
                // Phân loại theo lý do không khả dụng
                const reason = getDisableReason(dayjs(date));
                if (reason === "provider") classes += " calendar-disabled-provider";
                else if (reason === "system") classes += " calendar-disabled-system";
                return classes.trim();
              }
              return null;
            }}
          />
          {/* Legend */}
          <Box mt={2}>
            <Typography variant="body2">
              <span className="legend-color legend-provider"></span> Ngày off của thợ chụp hình
            </Typography>
            <Typography variant="body2">
              <span className="legend-color legend-system"></span> Ngày không khả dụng do hệ thống
            </Typography>

            <Typography variant="body2">
              <span className="legend-color legend-selected"></span> Ngày đang chọn
            </Typography>
          </Box>
        </Box>
      </Paper>

      {/* Chọn giờ */}
      {timeSlot && timeSlot.length > 0 && (
        <Paper
          variant="outlined"
          sx={{
            p: 2,
            borderRadius: 2,
            mb: 3,
            boxShadow: "0 4px 12px rgba(0,0,0,0.08)",
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant="subtitle1" fontWeight="600">
              {t("Chọn giờ")}
            </Typography>

          </Box>
          <Divider sx={{ mb: 2 }} />

          {/* Grid hiển thị giờ */}
          <Grid container spacing={2}>
            {timeSlot.map((slot, index) => (
              <Grid item xs={6} sm={4} md={3} key={slot.time}>
                <Paper
                  variant="outlined"
                  sx={{
                    p: 1.5,
                    borderRadius: 2,
                    textAlign: "center",
                    opacity: slot.is_available === 0 ? 0.5 : 1,
                    borderColor: index === checked ? theme.palette.primary.main : "#ddd",
                    backgroundColor: index === checked ? theme.palette.action.hover : "transparent",
                    transition: "all 0.3s ease",
                    "&:hover": {
                      cursor: slot.is_available === 0 ? "not-allowed" : "pointer",
                      backgroundColor: slot.is_available === 0 ? "transparent" : theme.palette.action.hover,
                    },
                  }}
                  onClick={() => {
                    if (slot.is_available !== 0) {
                      handleChange(slot, index);
                    }
                  }}
                >
                  <Radio
                    checked={index === checked}
                    disabled={slot.is_available === 0}
                    inputProps={{ "aria-label": slot.time }}
                    sx={{ p: 0, mb: 1 }}
                  />
                  <Typography variant="subtitle2" fontWeight="600">
                    {getTimeOfDay(slot.time)}
                  </Typography>
                  <Typography variant="body2">{slot.time}</Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Paper>
      )}

      {/* Trường hợp không có slot */}
      {(!timeSlot || timeSlot.length === 0) && (
        <Box textAlign="center" mt={4}>
          <Card sx={{ boxShadow: "none", mb: 2 }}>
            <CardMedia
              component="img"
              src={profileNoBooking}
              alt="no time slot"
              sx={{ width: 260, height: 260, margin: "0 auto" }}
            />
          </Card>
          <Typography variant="h6">{noSlotAvailable}</Typography>
        </Box>
      )}

      {/* Nút tiếp tục */}
      <Box mt={3}>
        <Button 
          variant="contained" 
          fullWidth 
          onClick={OpenNext}
          sx={{ py: 1.2, fontWeight: 600, textTransform: "none", borderRadius: 2 }}
        >
          {t("Tiếp tục")}
        </Button>
      </Box>

      {/* Backdrop chọn giờ tuỳ chỉnh */}
      <Backdrop
        open={customTime}
        sx={{
          zIndex: (theme) => theme.zIndex.modal + 1,
          backdropFilter: "blur(3px)",
          backgroundColor: "rgba(0,0,0,0.3)",
        }}
      >
        <Paper
          sx={{
            p: 3,
            borderRadius: 2,
            minWidth: 300,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "stretch",
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1" fontWeight={600}>
              {t("Chọn giờ tuỳ chỉnh")}
            </Typography>
            <IconButton onClick={() => handleClose(setCustomTime)}>
              <CloseRounded />
            </IconButton>
          </Box>
          <Divider />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label={t("Chọn giờ")}
              onChange={handleTimeChange}
              sx={{ mt: 1 }}
            />
          </LocalizationProvider>
          <Button
            variant="contained"
            sx={{ textTransform: "none", fontWeight: 600, borderRadius: 2, mt: 2 }}
            onClick={handleCustomTime}
          >
            {t("Xác nhận")}
          </Button>
        </Paper>
      </Backdrop>
    </Box>
  );
};

export default ConfirmDateTime;