// Header.jsx
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { MdOutlineSettings, MdOutlineShoppingCart } from "react-icons/md";
import {
  Box,
  IconButton,
  Drawer,
  Container,
  Avatar,
  Typography,
  Badge,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { ArrowBackIosNewOutlined } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { handleClose, handleOpen } from "../../../config/config";
import EmptyCart from "../Navigation/EmptyCart";
import Cart from "../../Reusable/Sections/CartItem";
import { t } from "i18next";
import EdemandSetting from "../Navigation/EdemandSetting";
import api from "../../../API/apiCollection";
import {
  setFrom,
  updateDoor,
  updateStore,
  updateProviderInfo,
} from "../../../redux/cart";
import { setCartOpen } from "../../../redux/DrawerConfig";
import { handleForce } from "../../../redux/Login";
import toast from "react-hot-toast";
import Authentication from "../Navigation/Authentication";
import { useNavigate } from "react-router";
import { IoReorderThree } from "react-icons/io5";
import Offcanvas from "react-bootstrap/Offcanvas";

// Các Drawer được import
import AddressDrawer from "../../../Drawers/AddressDrawer";
import ConfirmDateTime from "../../../Drawers/ConfirmDataTime";
import AddAddressForm from "../../../Drawers/AddAddressForm";
import Promocode from "../../../Drawers/Promocode";
import { dateDetails, slotDetails } from "../../../redux/orderCartDetails";
import { setSelectedPromocode } from "../../../redux/Promocode";
import BookingInfoDrawerNew from "../../../Drawers/BookingInfoDrawerNew";

// Import custom hook quản lý state địa chỉ
import { useAddressForm } from "../../../Drawers/hooks/useAddressForm";

// Import icon cho Bottom Navigation (mobile)
import HomeIcon from "@mui/icons-material/Home";
import CategoryIcon from "@mui/icons-material/Category";

// -------------------- Styled Components --------------------

// Styled Icon (không thay đổi nhiều, chỉ giữ hover hiệu ứng)
const StyledIcon = styled("div")(({ theme }) => ({
  transition: "transform 0.3s ease, color 0.3s ease",
  color: "inherit",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    transform: "scale(1.2)",
    color: "#2a8cf5",
  },

}));

// Styled Link (giữ nguyên hiệu ứng underline)
const StyledLink = styled(Link)(({ theme }) => ({
  transition: "color 0.3s ease, border-bottom 0.3s ease",
  color: "inherit",
  textDecoration: "none",
  padding: "8px 16px",
  position: "relative",
  display: "inline-block",
  "&:hover": {
    color: "#2a8cf5",
  },
  "&::after": {
    content: '""',
    position: "absolute",
    width: "0",
    height: "2px",
    backgroundColor: "#2a8cf5",
    left: "50%",
    bottom: "0",
    transition: "width 0.3s ease, left 0.3s ease",
  },
  "&:hover::after": {
    width: "100%",
    left: "0",
  },
}));

// Styled component cho Bottom Tab (mobile)
// Chú ý: chúng ta sẽ mở rộng một số selector để xử lý avatar (profile) và icon giỏ hàng (cart)
const TabBar = styled("nav")`
  --icon-active: #275efe;
  --icon-inactive: #9fa6b2;
  --icon-hover: #1a4fc1;
  --text: #000000;
  --circle: #e3f2fd;
  --background: #ffffff;
  --shadow: rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--background);
  border-top: 1px solid #ddd;
  border-radius: 12px 12px 0 0;
  height: 80px;
  padding: 0 12px;
  box-shadow: 0 -2px 8px var(--shadow);
  z-index: 1300;

  & > div {
    display: flex;
    height: 100%;
    position: relative;
  }

  & > div label {
    flex: 0 0 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    z-index: 1;
  }

  /* Áp dụng hiệu ứng cho svg, avatar (profile) và text */
  & > div label svg,
  & > div label .nav-avatar,
  & > div label span {
    display: block;
    backface-visibility: hidden;
    transition: transform 0.4s cubic-bezier(0.2, 0.64, 0.48, 1.12),
      opacity 0.4s ease, color 0.4s ease;
  }

  /* Hiệu ứng mặc định cho icon (svg) */
  & > div label svg {
    --y: 0;
    --s: 0.84;
    width: 32px;
    height: 32px;
    margin: auto;
    color: var(--icon-inactive);
    transform-origin: 50% 50%;
    transform: translateY(var(--y)) scale(var(--s));
  }

  /* Hiệu ứng mặc định cho avatar (profile) */
  & > div label .nav-avatar {
    --y: 0;
    --s: 0.84;
    transform-origin: 50% 50%;
    transform: translateY(var(--y)) scale(var(--s));
  }

  /* Hiệu ứng mặc định cho text (span) */
  & > div label span {
    --y: -8px;
    --s: 0.92;
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 0;
    opacity: 0;
    color: var(--text);
    transform-origin: 50% 0;
    transform: translateY(var(--y)) scale(var(--s));
  }

  & > div label:hover svg,
  & > div label:hover .nav-avatar {
    color: var(--icon-hover);
  }

  & > div label:active svg,
  & > div label:active .nav-avatar {
    --s: 0.76;
    transform: translateY(var(--y)) scale(var(--s));
  }

  & > div input {
    display: none;
  }

  /* Khi tab được chọn: tăng kích thước icon/avatar và dịch chuyển lên */
  & > div input:checked + label svg,
  & > div input:checked + label .nav-avatar {
    --y: -32px;
    --s: 1;
    color: var(--icon-active);
    transform: translateY(var(--y)) scale(var(--s));
  }

  /* Khi tab được chọn: hiển thị text */
  & > div input:checked + label span {
    --s: 1;
    --y: -14px;
    opacity: 1;
    transform: translateY(var(--y)) scale(var(--s));
  }

  & > div > .indicator {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 25%;
    transition: transform 0.4s cubic-bezier(0.2, 0.64, 0.48, 1.12);
    transform: translateX(0%);
    backface-visibility: hidden;
  }

  & > div > .indicator:after {
    content: "";
    width: 44px;
    height: 44px;
    display: block;
    border-radius: 50%;
    background: var(--circle);
    position: absolute;
    left: 50%;
    top: 0;
    box-shadow: 0 2px 8px var(--shadow);
    margin: -14px 0 0 -22px;
  }
`;

// -------------------- Header Component --------------------

const Header = ({ check, changeLight, changeDark }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  // Responsive: nếu màn hình ≤ 911px thì dùng Bottom Tab mobile
  const isBottomNav = useMediaQuery("(max-width:911px)");

  // Kiểm tra trạng thái đăng nhập
  const authentication = useSelector(
    (state) => state.authentication
  )?.isLoggedIn;
  const [islogined, setIsloggedIn] = useState(authentication);

  // State cho Drawer và các form
  const [openSetting, setOpenSetting] = useState(false);
  const cart = useSelector((state) => state.DrawerConfig)?.cartOpen;
  const setCart = (flag) => dispatch(setCartOpen(flag));
  const [form, setForm] = useState(false);
  const [login, isLogin] = useState(false);

  let web_settings = useSelector((state) => state.Settings)?.settings;
  web_settings = web_settings?.web_settings;

  const [selectSlote, isSelectedSlote] = useState(false);
  const [addAddress, setAddAddress] = useState(false);
  // eslint-disable-next-line
  const [addressFrom, setAddressForm] = useState(false);
  const [booking, setBooking] = useState(false);
  const [promo, setPromo] = useState(false);

  const locationData = useSelector((state) => state.Location);
  const cartDetails = useSelector((state) => state.cart);
  const profile = useSelector((state) => state.UserData)?.profile?.data;
  const profilePicture = profile?.image;

  // Các hàm mở/đóng Setting
  const handleOpenSetting = () => setOpenSetting(true);
  const handleCloseSetting = () => setOpenSetting(false);

  function ContinueClicked() {
    handleClose(setCart);
    handleOpen(setForm);
  }

  // Hàm mở cửa sổ đăng nhập (cho trường hợp chưa đăng nhập)
  const handleOpenLogin = () => isLogin(true);

  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const handleCloseOffcanvas = () => setShowOffcanvas(false);
  const handleShowOffcanvas = () => setShowOffcanvas(true);

  function BookingDetails() {
    handleClose(setForm);
    handleOpen(setBooking);
  }

  function OpenMapDrawer() {
    handleOpen(setAddAddress);
    handleClose(setForm);
  }

  function CompleteAddress() {
    handleClose(setAddAddress);
    handleOpen(setAddressForm);
  }

  function BookingDrawer() {
    handleClose(isSelectedSlote);
    handleOpen(setBooking);
  }

  const carts = useSelector((state) => state.cart);
  const handleCartOpening = async () => {
    dispatch(setFrom("cart"));
    if (islogined) {
      handleOpen(setCart);
      if (carts?.base_cart) {
        const provider_id =
          carts?.base_cart?.data?.[0]?.servic_details?.partner_id;
        if (provider_id) {
          try {
            const res = await api.get_providers({
              latitude: locationData.lat,
              longitude: locationData.lng,
              id: provider_id,
            });
            if (res?.data?.[0]) {
              dispatch(dateDetails(""));
              dispatch(slotDetails(""));
              dispatch(setSelectedPromocode(null));
              dispatch(updateDoor(res.data[0].at_doorstep));
              dispatch(updateStore(res.data[0].at_store));
              dispatch(updateProviderInfo(res.data[0]));
            } else {
              console.error("No provider data available.");
            }
          } catch (error) {
            console.error("Error fetching provider data:", error);
          }
        } else {
          console.error("Provider ID is not available.");
        }
      }
    } else {
      dispatch(handleForce(false));
      toast.error("You must be logged in to access this page.");
    }
  };

  // Sử dụng custom hook quản lý state địa chỉ
  const addressForm = useAddressForm();

  // -------------------- Mobile Bottom Tab --------------------
  // Định nghĩa các mục cho Bottom Tab (theo logic ứng dụng của bạn)
  const mobileNavItems = [
    {
      label: t("Trang chủ"),
      path: "/",
      icon: <HomeIcon style={{ fontSize: 32, color: "inherit" }} />,
    },
    {
      label: t("Dịch vụ"),
      path: "/categories",
      icon: <CategoryIcon style={{ fontSize: 32, color: "inherit" }} />,
    },
    {
      label: t("Giỏ hàng"),
      action: handleCartOpening,
      className: "cart-tab",
      icon: (
        <div style={{ position: "relative" }}>
          <MdOutlineShoppingCart className="nav-cart-icon" size={32} />
          {authentication && cartDetails?.base_cart?.data?.length > 0 && (
            <div
              style={{
                position: "absolute",
                top: "-5px",
                right: "-5px",
                backgroundColor: "#007BFF", // Màu xanh hiện đại
                color: "#fff",
                borderRadius: "50%",
                width: "20px",
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "12px",
                fontWeight: "bold",
                boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
              }}
            >
              {cartDetails?.base_cart?.data?.length}
            </div>
          )}
        </div>
      ),
    },
    {
      label: islogined ? t("Profile") : t("sign_in"),
      action: () => {
        if (islogined) {
          navigate("/profile/booking");
        } else {
          handleOpenLogin();
        }
      },
      icon: islogined ? (
        <Avatar
          className="nav-avatar"
          sx={{ height: 32, width: 32, color: "white" }}
          src={profilePicture || ""}
        />
      ) : (
        <FaUserCircle size={32} />
      ),
    },
  ];

  // Đồng bộ bottomNavValue với đường dẫn hiện tại
  const [bottomNavValue, setBottomNavValue] = useState(0);
  useEffect(() => {
    const path = location.pathname;
    if (path === "/") {
      setBottomNavValue(0);
    } else if (path === "/categories") {
      setBottomNavValue(1);
    } else if (path.startsWith("/profile")) {
      setBottomNavValue(3);
    }
    // Với tab Cart (không điều hướng) ta không cần xử lý
  }, [location.pathname]);

  // Hàm xử lý khi chọn một tab
  const handleTabChange = (index) => {
    setBottomNavValue(index);
    // Nếu chọn tab không phải “Giỏ hàng” (index 2) thì đóng Drawer của Cart
    if (index !== 2) {
      handleClose(setCart);
    }
    const selectedItem = mobileNavItems[index];
    if (selectedItem.path) {
      navigate(selectedItem.path);
    } else if (selectedItem.action) {
      selectedItem.action();
    }
  };

  return (
    <>
      <header>
        <Container className="mainContainer">
          <div className="navbarWrapper">
            <div className="leftDiv">
              <div className="logoWrapper">
                <Link to={"/"}>
                  <img src={web_settings?.web_logo} alt="logo" />
                </Link>
              </div>
            </div>

            {/* Nav links cho màn hình > 911px */}
            {!isBottomNav && (
              <div className="centerDiv">
                <ul className="linksWrapper">
                  <StyledLink
                    to="/"
                    className={location.pathname === "/" ? "navActive" : ""}
                  >
                    {t("home")}
                  </StyledLink>
                  <StyledLink
                    to="/about"
                    className={
                      location.pathname === "/about" ? "navActive" : ""
                    }
                  >
                    {t("about_us")}
                  </StyledLink>
                  <StyledLink
                    to="/categories"
                    className={
                      location.pathname === "/categories" ? "navActive" : ""
                    }
                  >
                    {t("all_category")}
                  </StyledLink>
                  <StyledLink
                    to="/providers"
                    className={
                      location.pathname === "/providers" ? "navActive" : ""
                    }
                  >
                    {t("all_providers")}
                  </StyledLink>
                  <StyledLink
                    to="/contact"
                    className={
                      location.pathname === "/contact" ? "navActive" : ""
                    }
                  >
                    {t("contact")}
                  </StyledLink>
                </ul>
              </div>
            )}

            <div className="rightDiv">
              <div>
                {/* Icon Cart và Drawer */}
                {!islogined ? (
                  <Drawer
                    anchor="right"
                    open={cart}
                    onClose={() => handleClose(setCart)}
                  >
                    <EmptyCart />
                  </Drawer>
                ) : (
                  <Drawer
                    anchor="right"
                    open={cart}
                    onClose={() => handleClose(setCart)}
                    sx={{
                      display: { xs: "block", sm: "block" },
                      "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: { md: 580, xs: "100%" },
                      },
                    }}
                  >
                    <Box
                      display={"flex"}
                      width={"100%"}
                      alignItems={"center"}
                      gap={1}
                      my={1}
                    >
                      <IconButton onClick={() => handleClose(setCart)}>
                        <ArrowBackIosNewOutlined fontSize="large" />
                      </IconButton>
                      <Typography
                        variant="h6"
                        textAlign={"center"}
                        fontWeight={"bold"}
                      >
                        {cartDetails?.base_cart?.data?.length > 0
                          ? `${t("Dịch vụ đã chọn của")} ${
                              cartDetails?.base_cart?.data[0]?.servic_details
                                ?.partner_name
                            }`
                          : t("cart")}
                      </Typography>
                    </Box>
                    <Cart continueClicked={ContinueClicked} />
                  </Drawer>
                )}

                <div>
                  <Badge
                    badgeContent={
                      authentication ? cartDetails?.base_cart?.data?.length : 0
                    }
                    color="primary"
                    showZero
                  >
                    <StyledIcon onClick={handleCartOpening}>
                      <MdOutlineShoppingCart size={32} />
                    </StyledIcon>
                  </Badge>
                </div>
              </div>

              <div>
                <StyledIcon onClick={handleOpenSetting}>
                  <MdOutlineSettings size={32} />
                </StyledIcon>
              </div>

              <div>
                <Authentication
                  login={login}
                  isLogin={isLogin}
                  setIsloggedIn={setIsloggedIn}
                />
                {islogined ? (
                  <IconButton
                    id="logined_user"
                    onClick={() => navigate("/profile/booking")}
                  >
                    <Avatar
                      sx={{ height: 32, width: 32, color: "white" }}
                      src={profilePicture || ""}
                    />
                  </IconButton>
                ) : (
                  <div className="btnWrapper" onClick={handleOpenLogin}>
                    <span>
                      <FaUserCircle size={32} />
                    </span>
                    <button className="commonBtn">{t("sign_in")}</button>
                  </div>
                )}
              </div>

              {/* Hiển thị hamburger & Offcanvas cho màn hình > 911px */}
              {!isBottomNav && (
                <span onClick={handleShowOffcanvas} id="hamburg">
                  <StyledIcon>
                    <IoReorderThree size={46} />
                  </StyledIcon>
                </span>
              )}
            </div>
          </div>

          {/* Offcanvas cho màn hình > 911px */}
          {!isBottomNav && (
            <Offcanvas
              show={showOffcanvas}
              onHide={handleCloseOffcanvas}
              placement="end"
              scroll={true}
              backdrop={true}
              className="header-offcanvas"
            >
              <Offcanvas.Header closeButton className="btn-close-white">
                <Offcanvas.Title></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <div className="navbarWrapper">
                  <div className="centerDiv">
                    <ul className="linksWrapper">
                      <StyledLink
                        to="/"
                        className={location.pathname === "/" ? "navActive" : ""}
                      >
                        {t("home")}
                      </StyledLink>
                      <StyledLink
                        to="/about"
                        className={
                          location.pathname === "/about" ? "navActive" : ""
                        }
                      >
                        {t("about_us")}
                      </StyledLink>
                      <StyledLink
                        to="/categories"
                        className={
                          location.pathname === "/categories" ? "navActive" : ""
                        }
                      >
                        {t("all_category")}
                      </StyledLink>
                      <StyledLink
                        to="/providers"
                        className={
                          location.pathname === "/providers" ? "navActive" : ""
                        }
                      >
                        {t("all_providers")}
                      </StyledLink>
                      <StyledLink
                        to="/contact"
                        className={
                          location.pathname === "/contact" ? "navActive" : ""
                        }
                      >
                        {t("contact")}
                      </StyledLink>
                    </ul>
                  </div>

                  <div className="rightDiv">
                    {!islogined ? (
                      <Drawer
                        anchor="right"
                        open={cart}
                        onClose={() => handleClose(setCart)}
                      >
                        <EmptyCart />
                      </Drawer>
                    ) : (
                      <Drawer
                        anchor="right"
                        open={cart}
                        onClose={() => handleClose(setCart)}
                        sx={{
                          display: { xs: "block", sm: "block" },
                          "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: { md: 580, xs: "100%" },
                          },
                        }}
                      >
                        <Box
                          display={"flex"}
                          width={"100%"}
                          alignItems={"center"}
                          gap={1}
                          my={1}
                        >
                          <IconButton onClick={() => handleClose(setCart)}>
                            <ArrowBackIosNewOutlined fontSize="large" />
                          </IconButton>
                          <Typography
                            variant="h6"
                            textAlign={"center"}
                            fontWeight={"bold"}
                          >
                            {cartDetails?.base_cart?.data?.length > 0
                              ? `${t("Dịch vụ đã chọn của")} ${
                                  cartDetails?.base_cart?.data[0]
                                    ?.servic_details?.partner_name
                                }`
                              : t("cart")}
                          </Typography>
                        </Box>
                        <Cart continueClicked={ContinueClicked} />
                      </Drawer>
                    )}

                    <div>
                      <Badge
                        badgeContent={
                          authentication
                            ? cartDetails?.base_cart?.data?.length
                            : 0
                        }
                        color="primary"
                        showZero
                      >
                        <StyledIcon onClick={handleCartOpening}>
                          <MdOutlineShoppingCart size={32} />
                        </StyledIcon>
                      </Badge>
                    </div>
                  </div>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          )}

          {/* Setting Drawer */}
          <Drawer
            anchor="right"
            open={openSetting}
            onClose={handleCloseSetting}
            sx={{
              display: { xs: "block", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { md: 580, xs: "100%" },
              },
            }}
          >
            <EdemandSetting
              changeDark={changeDark}
              changeLight={changeLight}
              setOpenSetting={setOpenSetting}
            />
          </Drawer>

          {/* ADDRESS DRAWER */}
          <Drawer
            open={form}
            anchor="right"
            sx={{
              display: { xs: "block", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { md: 580, xs: "100%" },
              },
            }}
          >
            <Box>
              <AddressDrawer
                addressForm={addressForm}
                setCart={setCart}
                setForm={setForm}
                isSelectedSlote={isSelectedSlote}
                continueFun={BookingDetails}
                MyFun={OpenMapDrawer}
                setBooking={setBooking}
              />
            </Box>
          </Drawer>

          {/* Confirm DateTime Drawer */}
          <Drawer
            open={selectSlote}
            anchor="right"
            sx={{
              display: { xs: "block", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { md: 580, xs: "100%" },
              },
            }}
          >
            <Box>
              <ConfirmDateTime
                setForm={setForm}
                isSelectSlote={isSelectedSlote}
                booking={BookingDrawer}
              />
            </Box>
          </Drawer>

          {/* Add Address Drawer */}
          <Drawer
            open={addAddress}
            anchor="right"
            sx={{
              display: { xs: "block", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { md: 580, xs: "100%" },
              },
            }}
          >
            <Box>
              <AddAddressForm
                CompleteAddress={CompleteAddress}
                setForm={setForm}
                addAddress={setAddAddress}
              />
            </Box>
          </Drawer>

          {/* Booking Information Drawer */}
          <Drawer
            open={booking}
            anchor="right"
            sx={{
              display: { xs: "block", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { md: 580, xs: "100%" },
              },
            }}
          >
            <Box>
              <BookingInfoDrawerNew
                setForm={setForm}
                setBooking={setBooking}
                setPromo={setPromo}
                addressForm={addressForm}
              />
            </Box>
          </Drawer>

          {/* Promocode Drawer */}
          <Drawer
            open={promo}
            anchor="right"
            sx={{
              display: { xs: "block", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: { md: 580, xs: "100%" },
              },
            }}
          >
            <Box>
              <Promocode setBooking={setBooking} setPromo={setPromo} />
            </Box>
          </Drawer>
        </Container>
      </header>

      {/* --- Bottom Tab dành cho mobile (≤ 911px) --- */}
      {isBottomNav && (
        <TabBar>
          <div>
            {mobileNavItems.map((item, index) => (
              <React.Fragment key={index}>
                <input
                  id={`menu-${index + 1}`}
                  type="radio"
                  name="menu"
                  checked={bottomNavValue === index}
                  onChange={() => handleTabChange(index)}
                />
                <label
                  htmlFor={`menu-${index + 1}`}
                  className={item.className ? item.className : ""}
                  onClick={() => handleTabChange(index)}
                >
                  {item.icon}
                  <span>{item.label}</span>
                </label>
              </React.Fragment>
            ))}
            {/* Phần indicator di chuyển */}
            <span
              className="indicator"
              style={{ transform: `translateX(${bottomNavValue * 100}%)` }}
            ></span>
          </div>
        </TabBar>
      )}
    </>
  );
};

export default Header;
