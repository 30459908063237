// AddressDrawer.jsx
import React, { useMemo, useCallback, memo, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
  styled,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  Grow,
  InputAdornment,
} from "@mui/material";
import { ArrowBackIosNewOutlined, CheckCircle, Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { handleClose, handleOpen } from "../config/config";
import { t } from "i18next";
import api from "../API/apiCollection";
import toast from "react-hot-toast";

// Redux Actions
import { cartDetails } from "../redux/orderCartDetails";
import { setDeliveryAddressType } from "../redux/DeliveryAddress";

// Custom Component
import DateTimeSelector from "./DateTimeSelector";

// Styled Components
const HeaderTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: 700,
  color: theme.palette.text.primary,
  letterSpacing: "0.5px",
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.25rem",
  fontWeight: 600,
  marginBottom: "16px",
  color: theme.palette.text.primary,
  letterSpacing: "0.25px",
}));

const SubSectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: 500,
  marginBottom: "8px",
  color: theme.palette.text.secondary,
  letterSpacing: "0.15px",
}));

const PrimaryButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "#FFFFFF",
  borderRadius: "8px",
  textTransform: "none",
  fontSize: "16px",
  fontWeight: 500,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    opacity: 0.9,
  },
  padding: "12px 0",
  marginTop: "24px",
}));

const TruncatedTitle = styled(Typography)(({ theme }) => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  fontSize: "1rem",
  fontWeight: 600,
  color: theme.palette.text.primary,
}));

const TruncatedDescription = styled(Typography)(({ theme }) => ({
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontSize: "0.875rem",
  color: theme.palette.text.secondary,
}));

// ProvinceCard Component
const ProvinceCard = memo(({ province, isSelected, onSelect }) => {
  const theme = useTheme();
  return (
    <Card
      onClick={() => onSelect(province)}
      sx={{
        cursor: "pointer",
        border: isSelected
          ? `2px solid ${theme.palette.primary.main}`
          : "1px solid #e0e0e0",
        boxShadow: isSelected ? `0 4px 20px rgba(2, 119, 250, 0.2)` : "none",
        transition: "border 0.3s ease, box-shadow 0.3s ease",
        "&:hover": { boxShadow: `0 4px 20px rgba(0, 0, 0, 0.1)` },
        height: "60px",
        minWidth: "150px",
        maxWidth: "250px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
      elevation={isSelected ? 8 : 2}
    >
      <CardContent sx={{ padding: "8px", paddingBottom: "8px !important" }}>
        <TruncatedTitle variant="h6" align="center" title={province.name_with_type}>
          {province.name_with_type}
        </TruncatedTitle>
      </CardContent>
    </Card>
  );
});

// FamousPlaceCard Component
const FamousPlaceCard = memo(({ place, isSelected, onSelect }) => {
  const theme = useTheme();
  return (
    <Grow in timeout={300}>
      <Card
        onClick={() => onSelect(place)}
        sx={{
          cursor: "pointer",
          position: "relative",
          border: isSelected
            ? `2px solid ${theme.palette.primary.main}`
            : "1px solid #e0e0e0",
          boxShadow: isSelected
            ? `0 4px 20px rgba(2, 119, 250, 0.2)`
            : "none",
          transition: "border 0.3s ease, box-shadow 0.3s ease",
          "&:hover": { boxShadow: `0 4px 20px rgba(0, 0, 0, 0.1)` },
          display: "flex",
          flexDirection: "column",
          height: "200px",
          maxWidth: "100%",
        }}
        elevation={isSelected ? 8 : 2}
      >
        <CardMedia
          component="img"
          height="120"
          image={JSON.parse(place.images)[0]} // Lấy hình ảnh đầu tiên
          alt={place.name}
          sx={{ objectFit: "cover" }}
        />
        <CardContent sx={{ flexGrow: 1, padding: "8px" }}>
          <TruncatedTitle variant="h6" title={place.name}>
            {place.name}
          </TruncatedTitle>
          <TruncatedDescription variant="body2">
            {place.short_description}
          </TruncatedDescription>
        </CardContent>
        {isSelected && (
          <CheckCircle
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: theme.palette.primary.main,
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              borderRadius: "50%",
            }}
          />
        )}
      </Card>
    </Grow>
  );
});

// Main AddressDrawer Component
// Nhận prop "addressForm" chứa các state và setter từ custom hook useAddressForm
const AddressDrawer = ({
  addressForm,
  setForm,
  isSelectedSlote,
  continueFun,
  MyFun,
  setCart,
  setBooking,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Lấy các state và setter từ addressForm
  const {
    selectedProvince,
    setSelectedProvince,
    filteredFamousPlaces,
    setFilteredFamousPlaces,
    selectedFamousPlace,
    setSelectedFamousPlace,
    openOtherLocation,
    setOpenOtherLocation,
    otherLocation,
    setOtherLocation,
    note,
    setNote,
    visibleFamousPlaces,
    setVisibleFamousPlaces,
    resetFamousPlace,
  } = addressForm;

  // Các selectors khác từ Redux
  const cart = useSelector((state) => state.cart);
  const baseCart = useSelector((state) => state.cart)?.base_cart;
  const orderDetails = useSelector((state) => state.OrderCartDetails)?.orderDetails;
  const selectedDate = orderDetails?.date;
  const isselectedTime = orderDetails?.slot;
  const delivery_type = useSelector((state) => state.DeliveryAddress)?.deliveryType;
  const deliveryAddress = useSelector((state) => state.DeliveryAddress)?.delivery;
  const provincesList = useSelector((state) => state.HinhcoLocation)?.provincesList;
  const famousPlaceList = useSelector((state) => state.HinhcoLocation)?.famousPlaceData?.famous_places;

  // Lấy danh sách tỉnh của nhà cung cấp dựa trên provider info
  const providerScope = cart?.provider_info?.scope_of_operation || "[]";
  const providerProvinces = useMemo(() => JSON.parse(providerScope).map((item) => item.code), [providerScope]);

  // Kiểm tra slot (logic giữ nguyên hoặc điều chỉnh theo nghiệp vụ của bạn)
  const checkSlot = useCallback(async () => {
    const partner_id = baseCart && baseCart.provider_id;
    try {
      const response = await api.checkSlots({
        partner_id: partner_id,
        date: selectedDate,
        time: isselectedTime,
      });
      if (response.error === false) {
        if (deliveryAddress !== "" && delivery_type === "Home") {
          providerAvailable(-1, -1);
        } else {
          handleClose(setForm);
          handleOpen(setBooking);
        }
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(t("Đã xảy ra lỗi. Vui lòng thử lại."));
    }
  }, [
    baseCart,
    selectedDate,
    isselectedTime,
    deliveryAddress,
    delivery_type,
    setForm,
    setBooking,
  ]);

  // Kiểm tra khả năng cung cấp dịch vụ dựa trên vị trí (nếu cần)
  const providerAvailable = useCallback(async (lat, lng) => {
    try {
      const result = await api.providerAvailable({
        latitude: lat,
        longitude: lng,
        isCheckout: 1,
      });
      if (result.error === false) {
        toast.success(result.message);
        handleClose(setForm);
        handleOpen(setBooking);
      } else {
        toast.error(t("Dịch vụ không khả dụng."));
      }
    } catch (error) {
      console.error(error);
      toast.error(t("Đã xảy ra lỗi. Vui lòng thử lại."));
    }
  }, [setForm, setBooking]);

  // Xử lý khi người dùng nhấn Continue
  const ContinueClicked = useCallback(() => {
    if (cart.at_store === "1" && cart.at_door === "1") {
      if (cart?.selectedView === null) {
        toast.error(t("Vui lòng chọn phương thức chụp!"));
        return;
      }
    }
    if (!selectedDate || !isselectedTime) {
      toast.error(t("Vui lòng chọn ngày và giờ chụp trước khi tiếp tục."));
      return;
    }
    if (delivery_type && delivery_type === "") {
      toast.error(t("Vui lòng chọn hình thức chụp trước khi tiếp tục."));
      return;
    }
    // Nếu chọn chụp tại cửa hàng
    if (cart.at_store === "1" && cart.at_door === "0") {
      dispatch(setDeliveryAddressType("shop"));
    }
    if (delivery_type === "Other" && otherLocation.trim() === "") {
      toast.error(t("Vui lòng nhập địa điểm chụp."));
      return;
    }
    checkSlot();
  }, [
    cart.at_store,
    cart.at_door,
    cart.selectedView,
    selectedDate,
    isselectedTime,
    delivery_type,
    otherLocation,
    dispatch,
    checkSlot,
  ]);

  // Hàm mở bước tiếp theo (ví dụ mở drawer chọn ngày giờ)
  const OpenNext = useCallback(() => {
    handleClose(setForm);
    handleOpen(isSelectedSlote);
  }, [setForm, isSelectedSlote]);

  // Hàm quay lại (ví dụ: đóng drawer hiện tại, mở drawer giỏ hàng)
  const back = useCallback(() => {
    handleClose(setForm);
    handleOpen(setCart);
  }, [setForm, setCart]);

  // Xử lý chọn phương thức địa chỉ (Home, Famous, v.v)
  const handleSelectMethod = useCallback(
    (method) => {
      dispatch(setDeliveryAddressType(method));
    },
    [dispatch]
  );

  // Cập nhật ghi chú
  const handleOrderNote = useCallback(
    (value) => {
      setNote(value);
      dispatch(cartDetails({ orderNote: value }));
    },
    [dispatch, setNote]
  );

  // Xử lý chọn tỉnh -> lọc danh sách địa điểm nổi tiếng theo tỉnh đó
  const handleSelectProvince = useCallback(
    (province) => {
      setSelectedProvince(province);
      const filtered = famousPlaceList.filter(
        (place) => place.province_code === province.code
      );
      setFilteredFamousPlaces(filtered);
      dispatch(setDeliveryAddressType("Famous"));
    },
    [famousPlaceList, dispatch, setSelectedProvince, setFilteredFamousPlaces]
  );

  // Xử lý chọn địa điểm nổi tiếng
  const handleSelectFamousPlace = useCallback(
    (place) => {
      if (selectedFamousPlace && selectedFamousPlace.id === place.id) {
        resetFamousPlace();
        dispatch(cartDetails({ selectedLocation: "" }));
        setOtherLocation("");
      } else {
        setSelectedFamousPlace(place);
        dispatch(cartDetails({ selectedLocation: place.name }));
        setOtherLocation(place.name);
      }
    },
    [selectedFamousPlace, dispatch, resetFamousPlace, setSelectedFamousPlace, setOtherLocation]
  );

  // Xử lý mở Dialog nhập địa chỉ khác (nếu không chọn nổi địa điểm nổi tiếng)
  const handleOpenOtherLocation = useCallback(() => {
    setOpenOtherLocation(true);
  }, []);

  const handleCloseOtherLocation = useCallback(() => {
    setOpenOtherLocation(false);
    setOtherLocation("");
  }, []);

  const handleSaveOtherLocation = useCallback(() => {
    if (otherLocation.trim() === "") {
      toast.error(t("Vui lòng nhập địa điểm chụp."));
      return;
    }
    dispatch(cartDetails({ selectedLocation: otherLocation }));
    setOpenOtherLocation(false);
    resetFamousPlace();
  }, [otherLocation, dispatch, resetFamousPlace]);

  // Tính toán danh sách tỉnh khả dụng dựa trên providerProvinces
  const availableProvinces = useMemo(() => {
    return provincesList.filter((province) =>
      providerProvinces.includes(province.code)
    );
  }, [provincesList, providerProvinces]);

  // Tính toán danh sách địa điểm nổi tiếng hiển thị dựa trên số lượng đã cho (visibleFamousPlaces)
  const displayedFamousPlaces = useMemo(() => {
    return filteredFamousPlaces.slice(0, visibleFamousPlaces);
  }, [filteredFamousPlaces, visibleFamousPlaces]);

  // Xử lý hiển thị thêm các địa điểm nổi tiếng
  const handleShowMoreFamousPlaces = useCallback(() => {
    setVisibleFamousPlaces((prev) => Math.min(prev + 4, 10));
  }, [setVisibleFamousPlaces]);

  // Xử lý xóa lựa chọn địa điểm nổi tiếng (để cho phép nhập tay)
  const handleClearSelectedPlace = useCallback(() => {
    resetFamousPlace();
    dispatch(cartDetails({ selectedLocation: "" }));
    setOtherLocation("");
  }, [dispatch, resetFamousPlace, setOtherLocation]);

  return (
    <Box
      className="overflow-hidden"
      sx={{
        backgroundColor: theme.palette.background.paper,
        minHeight: "100vh",
        paddingBottom: "80px",
      }}
    >
      {/* Header của Drawer */}
      <Box mt={2} mb={1} display="flex" alignItems="center" px={2}>
        <IconButton onClick={back} size="large">
          <ArrowBackIosNewOutlined
            sx={{ color: theme.palette.text.primary }}
            fontSize="inherit"
          />
        </IconButton>
        <HeaderTitle ml={1}>
          {t("Chọn địa điểm và thời gian chụp")}
        </HeaderTitle>
      </Box>
      <Divider />

      {/* Chọn ngày và giờ chụp */}
      <Box display="block" px={2} py={3}>
        <SectionTitle>{t("Chọn ngày và giờ chụp")}</SectionTitle>
        <DateTimeSelector
          onOpen={OpenNext}
          selectedDate={selectedDate}
          selectedTime={isselectedTime}
        />
      </Box>

      {/* Chọn địa điểm */}
      <Box px={2} mt={2}>
        <SectionTitle>{t("Bạn muốn chụp ở đâu?")}</SectionTitle>
        {/* Hiển thị các Province từ availableProvinces */}
        <Grid container spacing={2}>
          {availableProvinces.map((province) => (
            <Grid item key={province.code} xs="auto">
              <ProvinceCard
                province={province}
                isSelected={selectedProvince?.code === province.code}
                onSelect={handleSelectProvince}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Nếu đã chọn tỉnh, hiển thị input địa chỉ và danh sách địa điểm nổi tiếng */}
      {selectedProvince && (
        <Box px={2} mt={4}>
          <TextField
            fullWidth
            label={t(`Nhập địa chỉ bạn muốn chụp tại ${selectedProvince.name_with_type}`)}
            variant="outlined"
            value={otherLocation}
            onChange={(e) => {
              setOtherLocation(e.target.value);
              if (selectedFamousPlace) {
                handleClearSelectedPlace();
              }
            }}
            placeholder={
              selectedFamousPlace
                ? t("Bạn đã chọn địa điểm nổi tiếng, nếu muốn thay đổi hay nhấp vào đây")
                : t("Nhập địa chỉ cụ thể...")
            }
            sx={{ marginBottom: "24px" }}
            InputProps={{
              readOnly: !!selectedFamousPlace,
              endAdornment: selectedFamousPlace && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear selection"
                    onClick={handleClearSelectedPlace}
                    edge="end"
                  >
                    <Close />
                  </IconButton>
                </InputAdornment>
              ),
              sx: {
                backgroundColor: selectedFamousPlace
                  ? theme.palette.action.disabledBackground
                  : theme.palette.background.paper,
                cursor: selectedFamousPlace ? "pointer" : "text",
              },
            }}
            helperText={
              selectedFamousPlace &&
              t("Bạn đã chọn địa điểm nổi tiếng, nếu muốn thay đổi hay nhấp vào đây")
            }
            onClick={() => {
              if (selectedFamousPlace) {
                handleClearSelectedPlace();
              }
            }}
          />

          <SubSectionTitle>
            {t("Hoặc bạn có thể chọn các địa điểm nổi tiếng dưới đây")}
          </SubSectionTitle>
          <Grid container spacing={3}>
            {displayedFamousPlaces.map((place) => (
              <Grid item xs={12} key={place.id}>
                <FamousPlaceCard
                  place={place}
                  isSelected={selectedFamousPlace?.id === place.id}
                  onSelect={handleSelectFamousPlace}
                />
              </Grid>
            ))}
          </Grid>

          {filteredFamousPlaces.length > visibleFamousPlaces && (
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                variant="text"
                onClick={handleShowMoreFamousPlaces}
                disabled={visibleFamousPlaces >= 10}
              >
                {t("Xem thêm")}
              </Button>
            </Box>
          )}
        </Box>
      )}

      {/* Ghi chú cho nhiếp ảnh gia */}
      <Box px={2} mt={4}>
        <SectionTitle>{t("Ghi chú cho nhiếp ảnh gia (tùy chọn)")}</SectionTitle>
        <TextField
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          placeholder={t("Nhập yêu cầu hoặc ghi chú thêm...")}
          value={note}
          onChange={(e) => handleOrderNote(e.target.value)}
          sx={{
            backgroundColor: theme.palette.background.default,
            borderRadius: "8px",
          }}
        />
      </Box>

      {/* Nút Continue */}
      <Box px={2} pt={4} mb={4}>
        <PrimaryButton fullWidth onClick={ContinueClicked}>
          {t("Đặt lịch ngay")}
        </PrimaryButton>
      </Box>

      {/* Dialog nhập địa điểm khác */}
      <Dialog
        open={openOtherLocation}
        onClose={handleCloseOtherLocation}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{t("Nhập địa điểm chụp khác")}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={t("Địa điểm")}
            type="text"
            fullWidth
            variant="outlined"
            value={otherLocation}
            onChange={(e) => setOtherLocation(e.target.value)}
            sx={{ marginTop: "8px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOtherLocation} color="secondary">
            {t("Hủy")}
          </Button>
          <Button onClick={handleSaveOtherLocation} variant="contained" color="primary">
            {t("Lưu")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default memo(AddressDrawer);